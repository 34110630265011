import React from 'react'

import logoVias from './assets/images/logo-blue.svg';
import logoALOALTO from './assets/images/a-l-o-a-l-t-o-copy-2.svg';
import infoIcon from './assets/images/info.svg'
import brusselMobilityLogo from './assets/images/brussels_mobility.png'

const buttonStyle = {

  width: '120px',
  height: '40px',
  backgroundColor: '#516f86',
  color: '#FFFFFF',
  cursor: 'pointer',
  border: 0,
  borderRadius: '3px',
  fontSize: '12px',
  alignSelf: 'right',

};

export default ({ onMoreInfoClick }) => <div style={{ height: '40px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'flex-start', margin: '20px 30px' }}>
  <img alt="logo Vias" className="logo" src={logoVias} style={{ alignSelf: 'center', height: '34px', paddingTop: '9px' }} />
  <span style={{ alignSelf: 'center', marginLeft: '60px', fontSize: '20px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal' }}>Safer Cities</span>
  <span style={{ alignSelf: 'center', marginLeft: '8px' }}>powered by</span>
  <img alt="ALOALTO logo" className="logo" src={logoALOALTO} style={{ alignSelf: 'center', marginLeft: '8px', height: '14px' }} />
  <div style={{ flexGrow: 1 }}></div>
  <span style={{ alignSelf: 'center', marginRight: '15px' }}>Financed by</span>
  <img alt="Brussel Mobility logo" className="logo" src={brusselMobilityLogo} style={{ alignSelf: 'center', marginRight: '40px', height: '50px' }} />
  <button style={buttonStyle} onClick={onMoreInfoClick}><img alt="infoIcon" src={infoIcon} style={{ verticalAlign: 'middle', marginRight: '12px' }} />More info</button>
</div>
