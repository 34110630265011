import React from 'react'

import closeIcon from './assets/images/close.svg'

const buttonStyle = {

  width: '120px',
  height: '40px',
  color: '#FFFFFF',
  cursor: 'pointer',
  border: 0,
  borderRadius: '3px',
  fontSize: '12px',
  alignSelf: 'right',

};

export default ({ onCloseClicked, isVisible }) => <div style={{
  position: 'absolute',
  width: '560px',
  height: '100%',
  backgroundColor: '#FFFFFF',
  alignItems: 'flex-start',
  padding: '20px 30px',
  zIndex: 100,
  right: 0,
  display: isVisible ? 'flex' : 'none'
}}>

  <button style={buttonStyle} onClick={onCloseClicked}><img alt="closeIcon" src={closeIcon} style={{ verticalAlign: 'middle', marginRight: '12px' }} /></button>
</div>
