// Kepler.gl Data processing APIs
import Processors from 'kepler.gl/processors';

import events from './data/events.csv';
import trips from './data/limes.csv';
export default [
  {
    data: Processors.processCsvData(events),
    info: {
      id: 'events1',
      label: 'Button single pressed Events',
      // small : #f7e483
      "color": [247, 228, 131],
    }
  },
  {
    data: Processors.processCsvData(events),
    info: {
      id: 'events2',
      label: 'Button double pressed Events',
      // medium : #f7b984
      "color": [247, 185, 132],
    }
  },
  {
    data: Processors.processCsvData(events),
    info: {
      id: 'events3',
      label: 'Button hold Events',
      // large : #f08d80
      "color": [240, 141, 128],
    }
  }
  ,
  {
    data: Processors.processCsvData(trips),
    info: {
      id: 'trips',
      label: 'Limes Trips'
    }
  }


]