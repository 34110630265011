export default `click_type,device,timestamp,hook_event,createTime,name,lat,lng
"click","362B86","2019-09-14T13:03:06Z","button_pressed_click","2019-09-14T13:03:09.573Z","button","50.8840333","4.41883"
"double_click","362983","2019-09-14T14:17:06Z","button_pressed_double_click","2019-09-14T14:17:07.812Z","button","50.8176","4.37658"
"double_click","3B30D4","2019-09-14T08:28:00Z","button_pressed_double_click","2019-09-14T08:28:03.045Z","button","50.8839166","4.41916"
"click","3B30D4","2019-09-14T08:34:37Z","button_pressed_click","2019-09-14T08:34:41.240Z","button","50.8841","4.41875"
"hold","3B30D4","2019-09-14T08:52:57Z","button_pressed_hold","2019-09-14T08:52:59.888Z","button","50.8840166","4.41908"
"click","3B30D4","2019-09-14T08:54:03Z","button_pressed_click","2019-09-14T08:54:06.708Z","button","50.884","4.4187"
"double_click","3B30D4","2019-09-14T10:55:28Z","button_pressed_double_click","2019-09-14T10:55:31.290Z","button","50.8839333","4.41926"
"click","3B30D4","2019-09-14T11:28:54Z","button_pressed_click","2019-09-14T11:28:57.407Z","button","50.85265","4.3476"
"double_click","362D7C","2019-07-05T13:28:08Z","button_pressed_double_click","2019-07-05T13:28:11.624Z","button","50.83515","4.35555"
"click","362D7C","2019-07-05T13:36:39Z","button_pressed_click","2019-07-05T13:36:42.124Z","button","50.8376666","4.35328"
"double_click","362D7C","2019-07-05T14:25:21Z","button_pressed_double_click","2019-07-05T14:25:24.855Z","button","50.8258","4.35488"
"double_click","362D7C","2019-07-05T14:26:50Z","button_pressed_double_click","2019-07-05T14:26:52.591Z","button","50.8230166","4.35408"
"click","362D7C","2019-07-05T14:40:20Z","button_pressed_click","2019-07-05T14:40:23.669Z","button","50.8163","4.34696"
"double_click","362D7C","2019-07-05T14:49:00Z","button_pressed_double_click","2019-07-05T14:49:03.379Z","button","50.8202166","4.35953"
"double_click","362D7C","2019-07-05T14:54:27Z","button_pressed_double_click","2019-07-05T14:54:30.511Z","button","50.8258666","4.35986"
"double_click","362D7C","2019-07-05T16:49:06Z","button_pressed_double_click","2019-07-05T16:49:09.026Z","button","50.8384833","4.36206"
"click","3B3293","2019-09-14T09:22:04Z","button_pressed_click","2019-09-14T09:22:07.887Z","button","50.8841833","4.4188"
"hold","3B3293","2019-09-14T10:42:34Z","button_pressed_hold","2019-09-14T10:42:37.558Z","button","50.82195","4.40256"
"double_click","3B0DD8","2019-09-14T11:44:23Z","button_pressed_double_click","2019-09-14T11:44:26.228Z","button","50.8840833","4.41885"
"double_click","36286F","2019-08-23T15:20:07Z","button_pressed_double_click","2019-08-23T15:20:09.921Z","button","50.8399166","4.39756"
"click","36286F","2019-08-23T15:21:53Z","button_pressed_click","2019-08-23T15:21:56.966Z","button","50.83995","4.3975"
"click","36286F","2019-08-23T15:27:07Z","button_pressed_click","2019-08-23T15:27:09.627Z","button","50.83855","4.3952"
"hold","36286F","2019-08-23T15:29:09Z","button_pressed_hold","2019-08-23T15:29:11.871Z","button","50.8390333","4.39018"
"click","36286F","2019-08-23T15:35:35Z","button_pressed_click","2019-08-23T15:35:38.628Z","button","50.8398333","4.39718"
"click","36286F","2019-08-23T15:36:44Z","button_pressed_click","2019-08-23T15:36:46.210Z","button","50.84","4.39765"
"hold","36286F","2019-08-23T15:41:24Z","button_pressed_hold","2019-08-23T15:41:25.875Z","button","50.8397833","4.39805"
"click","36286F","2019-08-23T15:42:35Z","button_pressed_click","2019-08-23T15:42:36.700Z","button","50.83965","4.39745"
"click","36286F","2019-08-23T15:43:45Z","button_pressed_click","2019-08-23T15:43:47.331Z","button","50.83975","4.39753"
"hold","36286F","2019-08-23T15:46:54Z","button_pressed_hold","2019-08-23T15:46:56.370Z","button","50.8393833","4.39796"
"click","36286F","2019-08-23T15:48:03Z","button_pressed_click","2019-08-23T15:48:04.939Z","button","50.8397","4.3978"
"double_click","36286F","2019-08-23T15:49:46Z","button_pressed_double_click","2019-08-23T15:49:47.766Z","button","50.8397833","4.39775"
"click","36286F","2019-08-23T16:05:11Z","button_pressed_click","2019-08-23T16:05:14.190Z","button","50.8399166","4.3974"
"double_click","36286F","2019-08-24T14:37:12Z","button_pressed_double_click","2019-08-24T14:37:14.604Z","button","50.8248833","4.36073"
"click","36286F","2019-08-24T15:15:47Z","button_pressed_click","2019-08-24T15:15:50.639Z","button","50.8378","4.3609"
"click","36286F","2019-08-24T15:23:07Z","button_pressed_click","2019-08-24T15:23:11.197Z","button","50.8428333","4.36048"
"click","36286F","2019-08-24T15:24:52Z","button_pressed_click","2019-08-24T15:24:55.431Z","button","50.8440666","4.36126"
"click","36286F","2019-08-24T15:26:26Z","button_pressed_click","2019-08-24T15:26:29.141Z","button","50.8460833","4.36583"
"click","36286F","2019-08-24T15:36:27Z","button_pressed_click","2019-08-24T15:36:31.410Z","button","50.8451333","4.36875"
"click","36286F","2019-08-24T15:41:04Z","button_pressed_click","2019-08-24T15:41:06.477Z","button","50.84815","4.36938"
"click","36286F","2019-08-24T15:58:29Z","button_pressed_click","2019-08-24T15:58:32.714Z","button","50.8541666","4.36206"
"click","36286F","2019-08-24T16:02:49Z","button_pressed_click","2019-08-24T16:02:53.045Z","button","50.8522333","4.3536"
"click","36286F","2019-08-24T16:15:49Z","button_pressed_click","2019-08-24T16:15:52.849Z","button","50.8428333","4.35281"
"click","36286F","2019-08-24T16:17:12Z","button_pressed_click","2019-08-24T16:17:14.761Z","button","50.8410833","4.35093"
"double_click","36286F","2019-08-24T16:18:22Z","button_pressed_double_click","2019-08-24T16:18:24.412Z","button","50.83825","4.34758"
"click","36286F","2019-08-24T16:23:29Z","button_pressed_click","2019-08-24T16:23:31.377Z","button","50.8307166","4.34791"
"click","36286F","2019-08-24T16:26:05Z","button_pressed_click","2019-08-24T16:26:07.411Z","button","50.8251833","4.34405"
"click","36286F","2019-08-24T16:36:32Z","button_pressed_click","2019-08-24T16:36:36.309Z","button","50.8243833","4.35503"
"click","36286F","2019-08-24T16:49:36Z","button_pressed_click","2019-08-24T16:49:38.092Z","button","50.8286","4.37235"
"click","36286F","2019-08-24T17:03:05Z","button_pressed_click","2019-08-24T17:03:09.764Z","button","50.8349166","4.38785"
"click","36286F","2019-08-24T17:11:24Z","button_pressed_click","2019-08-24T17:11:27.489Z","button","50.8403333","4.39756"
"click","36286F","2019-08-25T13:42:11Z","button_pressed_click","2019-08-25T13:42:14.307Z","button","50.8445833","4.3882"
"click","36286F","2019-08-25T13:49:11Z","button_pressed_click","2019-08-25T13:49:15.078Z","button","50.8407","4.3841"
"click","36286F","2019-08-25T13:50:23Z","button_pressed_click","2019-08-25T13:50:26.171Z","button","50.8396666","4.3851"
"click","36286F","2019-08-25T13:54:35Z","button_pressed_click","2019-08-25T13:54:38.926Z","button","50.8321333","4.37775"
"click","36286F","2019-08-25T13:56:55Z","button_pressed_click","2019-08-25T13:56:57.552Z","button","50.8281333","4.37255"
"click","36286F","2019-08-25T14:01:11Z","button_pressed_click","2019-08-25T14:01:14.267Z","button","50.8303333","4.37323"
"click","36286F","2019-08-25T14:05:54Z","button_pressed_click","2019-08-25T14:05:56.325Z","button","50.8399833","4.3641"
"click","36286F","2019-08-25T14:07:43Z","button_pressed_click","2019-08-25T14:07:45.362Z","button","50.8377833","4.35883"
"click","36286F","2019-08-25T14:17:25Z","button_pressed_click","2019-08-25T14:17:28.741Z","button","50.83345","4.35723"
"click","36286F","2019-08-25T14:20:32Z","button_pressed_click","2019-08-25T14:20:35.082Z","button","50.82925","4.3617"
"click","36286F","2019-08-25T14:21:36Z","button_pressed_click","2019-08-25T14:21:38.701Z","button","50.8291","4.3619"
"click","36286F","2019-08-25T14:28:05Z","button_pressed_click","2019-08-25T14:28:09.278Z","button","50.8221666","4.35528"
"click","36286F","2019-08-25T14:43:53Z","button_pressed_click","2019-08-25T14:43:56.297Z","button","50.8244166","4.34723"
"click","36286F","2019-08-25T14:46:36Z","button_pressed_click","2019-08-25T14:46:39.451Z","button","50.8226166","4.34265"
"click","36286F","2019-08-25T14:48:08Z","button_pressed_click","2019-08-25T14:48:10.371Z","button","50.8202666","4.33833"
"click","36286F","2019-08-25T14:51:36Z","button_pressed_click","2019-08-25T14:51:37.896Z","button","50.8135666","4.33018"
"click","36286F","2019-08-25T15:02:56Z","button_pressed_click","2019-08-25T15:03:00.132Z","button","50.8139333","4.35306"
"click","36286F","2019-08-25T15:10:48Z","button_pressed_click","2019-08-25T15:10:51.903Z","button","50.8183666","4.36298"
"click","36286F","2019-09-04T10:44:35Z","button_pressed_click","2019-09-04T10:44:38.656Z","button","50.8739333","4.50655"
"click","36286F","2019-09-04T15:12:06Z","button_pressed_click","2019-09-04T15:12:08.733Z","button","50.82565","4.37955"
"click","36286F","2019-09-04T15:13:32Z","button_pressed_click","2019-09-04T15:13:35.167Z","button","50.8257833","4.37948"
"click","36286F","2019-09-04T15:39:08Z","button_pressed_click","2019-09-04T15:39:12.190Z","button","50.8235","4.3709"
"click","36286F","2019-09-04T15:46:34Z","button_pressed_click","2019-09-04T15:46:37.922Z","button","50.8189333","4.37131"
"click","36286F","2019-09-04T16:18:07Z","button_pressed_click","2019-09-04T16:18:11.406Z","button","50.82775","4.37113"
"click","36286F","2019-09-04T16:29:12Z","button_pressed_click","2019-09-04T16:29:15.547Z","button","50.8313833","4.37846"
"click","36286F","2019-09-04T16:48:08Z","button_pressed_click","2019-09-04T16:48:11.820Z","button","50.8411166","4.3688"
"click","36286F","2019-09-05T13:29:06Z","button_pressed_click","2019-09-05T13:29:09.990Z","button","50.8397833","4.39788"
"click","36286F","2019-09-05T13:30:14Z","button_pressed_click","2019-09-05T13:30:16.415Z","button","50.8397166","4.39803"
"click","36286F","2019-09-05T14:01:01Z","button_pressed_click","2019-09-05T14:01:04.933Z","button","50.8511833","4.38293"
"double_click","36286F","2019-09-05T14:05:29Z","button_pressed_double_click","2019-09-05T14:05:32.423Z","button","50.85005","4.37501"
"double_click","36286F","2019-09-05T14:08:26Z","button_pressed_double_click","2019-09-05T14:08:29.023Z","button","50.85385","4.3707"
"double_click","36286F","2019-09-05T14:22:54Z","button_pressed_double_click","2019-09-05T14:22:56.526Z","button","50.8638","4.35916"
"double_click","36286F","2019-09-05T14:33:06Z","button_pressed_double_click","2019-09-05T14:33:09.859Z","button","50.8611666","4.35771"
"double_click","36286F","2019-09-05T14:34:17Z","button_pressed_double_click","2019-09-05T14:34:20.488Z","button","50.8564833","4.35505"
"double_click","36286F","2019-09-05T14:37:35Z","button_pressed_double_click","2019-09-05T14:37:37.582Z","button","50.85525","4.36093"
"double_click","36286F","2019-09-05T14:38:49Z","button_pressed_double_click","2019-09-05T14:38:51.692Z","button","50.8549166","4.36306"
"click","36286F","2019-09-05T14:42:11Z","button_pressed_click","2019-09-05T14:42:14.114Z","button","50.8492","4.36086"
"double_click","36286F","2019-09-05T14:59:49Z","button_pressed_double_click","2019-09-05T14:59:52.440Z","button","50.85175","4.35293"
"double_click","36286F","2019-09-05T15:05:14Z","button_pressed_double_click","2019-09-05T15:05:16.762Z","button","50.8480166","4.35633"
"double_click","36286F","2019-09-05T15:06:25Z","button_pressed_double_click","2019-09-05T15:06:28.146Z","button","50.8456666","4.3562"
"click","36286F","2019-09-05T15:11:53Z","button_pressed_click","2019-09-05T15:11:56.234Z","button","50.8417166","4.35116"
"double_click","36286F","2019-09-05T15:26:46Z","button_pressed_double_click","2019-09-05T15:26:49.494Z","button","50.843","4.35833"
"click","36286F","2019-09-05T15:32:54Z","button_pressed_click","2019-09-05T15:32:58.038Z","button","50.8401666","4.35963"
"double_click","36286F","2019-09-05T15:34:03Z","button_pressed_double_click","2019-09-05T15:34:05.444Z","button","50.83865","4.36191"
"double_click","36286F","2019-09-05T15:40:53Z","button_pressed_double_click","2019-09-05T15:40:57.704Z","button","50.84205","4.36693"
"double_click","36286F","2019-09-05T15:50:54Z","button_pressed_double_click","2019-09-05T15:50:58.699Z","button","50.8438666","4.36235"
"double_click","36286F","2019-09-05T16:07:29Z","button_pressed_double_click","2019-09-05T16:07:32.618Z","button","50.8401166","4.36405"
"double_click","36286F","2019-09-05T16:11:31Z","button_pressed_double_click","2019-09-05T16:11:33.165Z","button","50.8390666","4.36165"
"double_click","36286F","2019-09-05T16:12:57Z","button_pressed_double_click","2019-09-05T16:12:59.433Z","button","50.8372166","4.35681"
"double_click","36286F","2019-09-05T16:15:19Z","button_pressed_double_click","2019-09-05T16:15:21.665Z","button","50.8383666","4.35253"
"double_click","36286F","2019-09-05T16:16:52Z","button_pressed_double_click","2019-09-05T16:16:53.911Z","button","50.8379833","4.35183"
"double_click","36286F","2019-09-05T16:43:02Z","button_pressed_double_click","2019-09-05T16:43:04.287Z","button","50.8216333","4.37223"
"double_click","36286F","2019-09-05T16:50:43Z","button_pressed_double_click","2019-09-05T16:50:47.289Z","button","50.8198333","4.3851"
"double_click","36286F","2019-09-05T17:10:49Z","button_pressed_double_click","2019-09-05T17:10:53.570Z","button","50.8276333","4.38143"
"double_click","36286F","2019-09-06T13:53:00Z","button_pressed_double_click","2019-09-06T13:53:04.169Z","button","50.8446","4.377"
"click","36286F","2019-09-06T14:09:37Z","button_pressed_click","2019-09-06T14:09:39.745Z","button","50.8332333","4.37668"
"double_click","36286F","2019-09-06T14:28:22Z","button_pressed_double_click","2019-09-06T14:28:25.101Z","button","50.8213333","4.37073"
"double_click","36286F","2019-09-06T15:19:46Z","button_pressed_double_click","2019-09-06T15:19:50.349Z","button","50.8011166","4.37396"
"click","36286F","2019-09-06T15:23:07Z","button_pressed_click","2019-09-06T15:23:09.419Z","button","50.7992666","4.37875"
"click","36286F","2019-09-06T16:11:12Z","button_pressed_click","2019-09-06T16:11:15.547Z","button","50.81855","4.4053"
"double_click","36286F","2019-09-06T16:12:32Z","button_pressed_double_click","2019-09-06T16:12:34.488Z","button","50.8184","4.40463"
"double_click","36286F","2019-09-06T16:32:21Z","button_pressed_double_click","2019-09-06T16:32:24.782Z","button","50.8309333","4.39025"
"double_click","36286F","2019-09-06T16:36:48Z","button_pressed_double_click","2019-09-06T16:36:49.841Z","button","50.8368166","4.39453"
"double_click","36286F","2019-09-06T17:16:10Z","button_pressed_double_click","2019-09-06T17:16:12.150Z","button","50.8393166","4.39758"
"click","36286F","2019-09-08T13:31:28Z","button_pressed_click","2019-09-08T13:31:32.683Z","button","50.8393166","4.39736"
"double_click","36286F","2019-09-08T13:40:03Z","button_pressed_double_click","2019-09-08T13:40:07.183Z","button","50.8408333","4.39218"
"double_click","36286F","2019-09-08T14:10:05Z","button_pressed_double_click","2019-09-08T14:10:08.604Z","button","50.8489666","4.3847"
"double_click","36286F","2019-09-08T14:37:50Z","button_pressed_double_click","2019-09-08T14:37:53.695Z","button","50.8426","4.35918"
"double_click","36286F","2019-09-08T14:43:37Z","button_pressed_double_click","2019-09-08T14:43:41.406Z","button","50.84205","4.35916"
"double_click","36286F","2019-09-08T14:45:54Z","button_pressed_double_click","2019-09-08T14:45:56.911Z","button","50.8383833","4.36176"
"double_click","36286F","2019-09-08T14:57:53Z","button_pressed_double_click","2019-09-08T14:57:56.876Z","button","50.8268","4.37235"
"click","36286F","2019-09-08T15:01:10Z","button_pressed_click","2019-09-08T15:01:12.747Z","button","50.823","4.37318"
"double_click","36286F","2019-09-08T15:04:41Z","button_pressed_double_click","2019-09-08T15:04:43.965Z","button","50.8208666","4.3736"
"double_click","36286F","2019-09-08T15:10:49Z","button_pressed_double_click","2019-09-08T15:10:53.286Z","button","50.8197","4.38223"
"double_click","36286F","2019-09-08T15:12:09Z","button_pressed_double_click","2019-09-08T15:12:12.875Z","button","50.8201166","4.38265"
"double_click","36286F","2019-09-08T15:14:37Z","button_pressed_double_click","2019-09-08T15:14:39.690Z","button","50.8204333","4.3851"
"double_click","36286F","2019-09-08T15:16:32Z","button_pressed_double_click","2019-09-08T15:16:35.880Z","button","50.8180333","4.3785"
"double_click","36286F","2019-09-08T15:19:11Z","button_pressed_double_click","2019-09-08T15:19:13.569Z","button","50.8120833","4.37951"
"double_click","36286F","2019-09-08T15:32:35Z","button_pressed_double_click","2019-09-08T15:32:38.623Z","button","50.8016166","4.4011"
"double_click","36286F","2019-09-08T16:07:39Z","button_pressed_double_click","2019-09-08T16:07:43.167Z","button","50.8164","4.3921"
"double_click","36286F","2019-09-08T16:27:07Z","button_pressed_double_click","2019-09-08T16:27:11.033Z","button","50.8314","4.40461"
"double_click","36286F","2019-09-08T16:30:35Z","button_pressed_double_click","2019-09-08T16:30:37.915Z","button","50.8328166","4.41823"
"click","36286F","2019-09-08T16:52:52Z","button_pressed_click","2019-09-08T16:52:55.924Z","button","50.8330833","4.40213"
"click","36286F","2019-09-08T17:25:06Z","button_pressed_click","2019-09-08T17:25:09.855Z","button","50.8382666","4.39923"
"click","36286F","2019-09-11T13:31:28Z","button_pressed_click","2019-09-11T13:31:32.077Z","button","50.8395166","4.39766"
"click","36286F","2019-09-11T13:33:14Z","button_pressed_click","2019-09-11T13:33:16.221Z","button","50.8391166","4.39678"
"double_click","36286F","2019-09-11T13:37:30Z","button_pressed_double_click","2019-09-11T13:37:32.781Z","button","50.8416833","4.38545"
"click","36286F","2019-09-11T13:41:47Z","button_pressed_click","2019-09-11T13:41:48.849Z","button","50.8420666","4.38483"
"double_click","36286F","2019-09-11T14:15:21Z","button_pressed_double_click","2019-09-11T14:15:24.516Z","button","50.8324833","4.4009"
"double_click","36286F","2019-09-11T14:28:57Z","button_pressed_double_click","2019-09-11T14:29:01.013Z","button","50.8317333","4.40131"
"double_click","36286F","2019-09-11T14:34:49Z","button_pressed_double_click","2019-09-11T14:34:52.619Z","button","50.8295","4.3948"
"double_click","36286F","2019-09-11T14:37:11Z","button_pressed_double_click","2019-09-11T14:37:13.537Z","button","50.83385","4.39616"
"click","36286F","2019-09-11T14:45:14Z","button_pressed_click","2019-09-11T14:45:17.642Z","button","50.8340833","4.38995"
"double_click","36286F","2019-09-11T15:06:24Z","button_pressed_double_click","2019-09-11T15:06:28.495Z","button","50.8318166","4.37731"
"double_click","36286F","2019-09-11T15:07:39Z","button_pressed_double_click","2019-09-11T15:07:41.714Z","button","50.8335166","4.37818"
"double_click","36286F","2019-09-11T16:04:36Z","button_pressed_double_click","2019-09-11T16:04:38.862Z","button","50.8159","4.37788"
"double_click","36286F","2019-09-11T16:28:35Z","button_pressed_double_click","2019-09-11T16:28:39.156Z","button","50.8275833","4.38173"
"double_click","36286F","2019-09-11T16:39:04Z","button_pressed_double_click","2019-09-11T16:39:08.468Z","button","50.8271833","4.38066"
"double_click","36286F","2019-09-11T16:40:17Z","button_pressed_double_click","2019-09-11T16:40:19.281Z","button","50.82725","4.37741"
"double_click","36286F","2019-09-11T16:43:11Z","button_pressed_double_click","2019-09-11T16:43:13.434Z","button","50.8264","4.37288"
"double_click","36286F","2019-09-11T16:50:34Z","button_pressed_double_click","2019-09-11T16:50:38.735Z","button","50.828","4.36693"
"double_click","36286F","2019-09-12T13:42:46Z","button_pressed_double_click","2019-09-12T13:42:48.803Z","button","50.84135","4.38616"
"click","36286F","2019-09-12T13:43:54Z","button_pressed_click","2019-09-12T13:43:56.296Z","button","50.8414166","4.38636"
"hold","36286F","2019-09-12T13:45:48Z","button_pressed_hold","2019-09-12T13:45:51.086Z","button","50.84195","4.38583"
"double_click","36286F","2019-09-12T13:46:53Z","button_pressed_double_click","2019-09-12T13:46:56.713Z","button","50.84115","4.38651"
"click","36286F","2019-09-12T13:48:04Z","button_pressed_click","2019-09-12T13:48:06.043Z","button","50.84195","4.38618"
"double_click","3B6959","2019-09-14T11:48:30Z","button_pressed_double_click","2019-09-14T11:48:32.278Z","button","50.8389833","4.36765"
"click","3B6959","2019-09-14T11:55:08Z","button_pressed_click","2019-09-14T11:55:11.326Z","button","50.8374166","4.36268"
"double_click","3B6959","2019-09-14T12:27:19Z","button_pressed_double_click","2019-09-14T12:27:23.042Z","button","50.8273833","4.37176"
"double_click","3B6959","2019-09-14T12:34:21Z","button_pressed_double_click","2019-09-14T12:34:24.487Z","button","50.8180333","4.38016"
"double_click","3B695C","2019-09-16T10:27:04Z","button_pressed_double_click","2019-09-16T10:27:07.651Z","button","50.81685","4.39283"
"double_click","3B695C","2019-09-24T11:39:03Z","button_pressed_double_click","2019-09-24T11:39:08.134Z","button","50.82595","4.40181"
"double_click","3B695C","2019-10-05T06:31:41Z","button_pressed_double_click","2019-10-05T06:31:45.805Z","button","50.81705","4.38141"
"click","3B695C","2019-10-05T06:38:43Z","button_pressed_click","2019-10-05T06:38:45.161Z","button","50.81305","4.37965"
"click","3B695C","2019-10-05T16:50:05Z","button_pressed_click","2019-10-05T16:50:08.371Z","button","50.8163666","4.38231"
"click","3B695C","2019-10-07T11:49:01Z","button_pressed_click","2019-10-07T11:49:04.582Z","button","50.8223833","4.38961"
"click","3B695C","2019-10-07T11:51:17Z","button_pressed_click","2019-10-07T11:51:19.335Z","button","50.8194333","4.38641"
"click","362D79","2019-07-04T07:27:47Z","button_pressed_click","2019-07-04T07:27:49.426Z","button","50.883678","4.419865983"
"click","362D79","2019-07-04T08:45:22Z","button_pressed_click","2019-07-04T08:45:25.661Z","button","50.8836596","4.419869846"
"click","362D79","2019-07-04T09:34:00Z","button_pressed_click","2019-07-04T09:34:03.902Z","button","50.8835864","4.419872761"
"click","362D79","2019-07-04T11:40:41Z","button_pressed_click","2019-07-04T11:40:44.798Z","button","50.883678","4.419865983"
"click","362D79","2019-07-15T12:59:02Z","button_pressed_click","2019-07-15T12:59:04.715Z","button","50.8740592","4.506475925"
"hold","362984","2019-10-04T17:40:53Z","button_pressed_hold","2019-10-04T17:40:56.136Z","button","50.8308666","4.35123"
"click","362984","2019-10-04T17:42:04Z","button_pressed_click","2019-10-04T17:42:05.956Z","button","50.8309333","4.35125"
"click","362984","2019-10-07T07:46:05Z","button_pressed_click","2019-10-07T07:46:09.300Z","button","50.8260166","4.35426"
"click","362DFB","2019-08-21T12:14:45Z","button_pressed_click","2019-08-21T12:14:49.953Z","button","50.8471666","4.39463"
"click","362DFB","2019-08-21T13:01:48Z","button_pressed_click","2019-08-21T13:01:53.053Z","button","50.8334333","4.36913"
"click","362DFB","2019-08-21T16:04:52Z","button_pressed_click","2019-08-21T16:04:55.838Z","button","50.8284333","4.3865"
"click","362DFB","2019-08-22T14:03:41Z","button_pressed_click","2019-08-22T14:03:45.341Z","button","50.8558","4.37695"
"click","362DFB","2019-08-22T14:07:55Z","button_pressed_click","2019-08-22T14:07:57.707Z","button","50.8647666","4.38085"
"click","362DFB","2019-08-22T14:12:05Z","button_pressed_click","2019-08-22T14:12:08.555Z","button","50.8612666","4.36885"
"click","362DFB","2019-08-22T14:17:08Z","button_pressed_click","2019-08-22T14:17:11.454Z","button","50.8534833","4.36333"
"click","362DFB","2019-08-22T14:25:39Z","button_pressed_click","2019-08-22T14:25:42.327Z","button","50.8463","4.34751"
"click","362DFB","2019-08-22T14:27:06Z","button_pressed_click","2019-08-22T14:27:08.897Z","button","50.843","4.34396"
"click","362DFB","2019-08-22T14:31:11Z","button_pressed_click","2019-08-22T14:31:13.305Z","button","50.83435","4.34228"
"click","362DFB","2019-08-22T14:39:25Z","button_pressed_click","2019-08-22T14:39:28.560Z","button","50.8302","4.35866"
"click","362DFB","2019-08-22T14:58:15Z","button_pressed_click","2019-08-22T14:58:18.535Z","button","50.8230333","4.36656"
"click","362DFB","2019-08-22T14:59:24Z","button_pressed_click","2019-08-22T14:59:26.306Z","button","50.8217","4.36656"
"click","362DFB","2019-08-22T15:04:05Z","button_pressed_click","2019-08-22T15:04:07.784Z","button","50.81435","4.36146"
"click","362DFB","2019-08-22T15:14:02Z","button_pressed_click","2019-08-22T15:14:05.928Z","button","50.8220333","4.34498"
"click","362DFB","2019-08-22T15:20:39Z","button_pressed_click","2019-08-22T15:20:43.134Z","button","50.8089833","4.3451"
"click","362DFB","2019-08-22T15:41:54Z","button_pressed_click","2019-08-22T15:41:57.995Z","button","50.80915","4.33743"
"double_click","362DFB","2019-08-22T15:43:14Z","button_pressed_double_click","2019-08-22T15:43:16.151Z","button","50.8122333","4.33855"
"click","362DFB","2019-08-22T16:58:35Z","button_pressed_click","2019-08-22T16:58:38.644Z","button","50.8419","4.38646"
"click","362DFB","2019-08-22T17:18:36Z","button_pressed_click","2019-08-22T17:18:39.898Z","button","50.8411166","4.38463"
"click","362DFB","2019-08-22T17:45:27Z","button_pressed_click","2019-08-22T17:45:31.868Z","button","50.8738666","4.50648"
"click","362DFB","2019-09-12T15:03:54Z","button_pressed_click","2019-09-12T15:03:57.790Z","button","50.8593666","4.41458"
"click","362DFB","2019-09-12T15:56:17Z","button_pressed_click","2019-09-12T15:56:20.310Z","button","50.8574333","4.39688"
"double_click","362DFB","2019-09-15T13:48:36Z","button_pressed_double_click","2019-09-15T13:48:39.968Z","button","50.8438666","4.38791"
"double_click","362DFB","2019-09-15T13:49:47Z","button_pressed_double_click","2019-09-15T13:49:50.062Z","button","50.8440166","4.3878"
"double_click","362DFB","2019-09-15T13:50:56Z","button_pressed_double_click","2019-09-15T13:50:58.704Z","button","50.8438666","4.38788"
"double_click","362DFB","2019-09-15T13:52:04Z","button_pressed_double_click","2019-09-15T13:52:06.248Z","button","50.8440666","4.38788"
"click","362DFB","2019-09-15T13:53:14Z","button_pressed_click","2019-09-15T13:53:16.045Z","button","50.8439","4.38766"
"click","3619CE","2019-07-15T15:25:45Z","button_pressed_click","2019-07-15T15:25:48.361Z","button","50.8439333","4.38843"
"click","3619CE","2019-07-15T15:26:54Z","button_pressed_click","2019-07-15T15:26:57.254Z","button","50.8438666","4.38833"
"click","3619CE","2019-07-15T15:47:05Z","button_pressed_click","2019-07-15T15:47:09.364Z","button","50.8303833","4.39016"
"double_click","3619CE","2019-07-15T15:48:11Z","button_pressed_double_click","2019-07-15T15:48:14.255Z","button","50.8311666","4.38903"
"click","3619CE","2019-07-15T16:10:11Z","button_pressed_click","2019-07-15T16:10:14.750Z","button","50.8423166","4.3699"
"click","3619CE","2019-07-16T14:36:31Z","button_pressed_click","2019-07-16T14:36:39.647Z","button","50.8724833","4.3801"
"click","3619CE","2019-07-17T14:11:39Z","button_pressed_click","2019-07-17T14:11:41.563Z","button","50.8499","4.36416"
"click","3619CE","2019-07-17T14:12:51Z","button_pressed_click","2019-07-17T14:12:52.740Z","button","50.8475666","4.36295"
"click","3619CE","2019-07-17T14:26:03Z","button_pressed_click","2019-07-17T14:26:17.052Z","button","50.8417","4.36476"
"click","3619CE","2019-07-17T14:32:59Z","button_pressed_click","2019-07-17T14:33:02.289Z","button","50.8428166","4.36008"
"click","3619CE","2019-07-19T16:08:22Z","button_pressed_click","2019-07-19T16:08:25.565Z","button","50.8506","4.29773"
"hold","3619CE","2019-07-19T16:11:04Z","button_pressed_hold","2019-07-19T16:11:06.585Z","button","50.8506166","4.29771"
"click","3619CE","2019-07-19T16:15:25Z","button_pressed_click","2019-07-19T16:15:27.555Z","button","50.8574","4.30485"
"click","3619CE","2019-07-19T16:25:21Z","button_pressed_click","2019-07-19T16:25:24.750Z","button","50.8496333","4.31015"
"hold","3619CE","2019-07-19T16:34:56Z","button_pressed_hold","2019-07-19T16:34:59.934Z","button","50.8503833","4.2976"
"click","3619CE","2019-07-19T16:36:04Z","button_pressed_click","2019-07-19T16:36:06.258Z","button","50.8505","4.29805"
"double_click","3619CE","2019-07-22T10:51:36Z","button_pressed_double_click","2019-07-22T10:51:37.790Z","button","50.87405","4.50643"
"click","3619CE","2019-07-22T14:18:23Z","button_pressed_click","2019-07-22T14:18:27.603Z","button","50.7962833","4.40085"
"click","3619CE","2019-07-22T14:25:22Z","button_pressed_click","2019-07-22T14:25:27.846Z","button","50.8075","4.38735"
"click","3619CE","2019-07-22T14:28:23Z","button_pressed_click","2019-07-22T14:28:25.236Z","button","50.8139833","4.38486"
"click","3619CE","2019-07-22T15:14:48Z","button_pressed_click","2019-07-22T15:14:51.954Z","button","50.8401","4.39416"
"click","3619CE","2019-07-22T16:11:36Z","button_pressed_click","2019-07-22T16:11:40.395Z","button","50.83005","4.4335"
"click","3619CE","2019-07-23T14:14:39Z","button_pressed_click","2019-07-23T14:14:42.635Z","button","50.849","4.44151"
"click","3619CE","2019-07-23T14:56:03Z","button_pressed_click","2019-07-23T14:56:06.975Z","button","50.8609166","4.42185"
"click","3619CE","2019-07-23T15:39:40Z","button_pressed_click","2019-07-23T15:39:43.189Z","button","50.8297166","4.40711"
"click","3619CE","2019-07-23T15:49:14Z","button_pressed_click","2019-07-23T15:49:17.990Z","button","50.8245","4.39633"
"click","3619CE","2019-07-23T16:54:15Z","button_pressed_click","2019-07-23T16:54:17.930Z","button","50.81855","4.4506"
"click","3619CE","2019-07-24T14:13:16Z","button_pressed_click","2019-07-24T14:13:19.671Z","button","50.8370666","4.4111"
"click","3619CE","2019-07-24T14:58:55Z","button_pressed_click","2019-07-24T14:58:58.572Z","button","50.8644333","4.41485"
"click","3619CE","2019-07-24T15:41:45Z","button_pressed_click","2019-07-24T15:41:48.865Z","button","50.8119833","4.38536"
"click","3619CE","2019-07-24T16:06:49Z","button_pressed_click","2019-07-24T16:06:52.341Z","button","50.8110333","4.38193"
"click","3619CE","2019-07-25T16:32:26Z","button_pressed_click","2019-07-25T16:32:29.387Z","button","50.83555","4.35181"
"click","3619CE","2019-07-26T13:53:27Z","button_pressed_click","2019-07-26T13:53:30.831Z","button","50.84065","4.39221"
"click","3619CE","2019-07-26T14:03:20Z","button_pressed_click","2019-07-26T14:03:23.640Z","button","50.8579","4.39726"
"click","3619CE","2019-07-26T15:12:56Z","button_pressed_click","2019-07-26T15:13:00.374Z","button","50.85505","4.35698"
"click","3619CE","2019-07-26T15:29:15Z","button_pressed_click","2019-07-26T15:29:18.957Z","button","50.84175","4.36676"
"click","3619CE","2019-07-26T15:46:59Z","button_pressed_click","2019-07-26T15:47:03.349Z","button","50.8450833","4.38573"
"double_click","3619CE","2019-07-26T16:02:29Z","button_pressed_double_click","2019-07-26T16:02:32.869Z","button","50.8432833","4.39106"
"click","3619CE","2019-07-26T16:03:38Z","button_pressed_click","2019-07-26T16:03:41.544Z","button","50.8434333","4.3905"
"click","3619CE","2019-07-26T16:21:18Z","button_pressed_click","2019-07-26T16:21:22.297Z","button","50.8505166","4.39465"
"click","3619CE","2019-07-26T16:30:31Z","button_pressed_click","2019-07-26T16:30:34.713Z","button","50.84935","4.37425"
"click","3619CE","2019-07-29T14:14:36Z","button_pressed_click","2019-07-29T14:14:39.384Z","button","50.8492666","4.44166"
"click","3619CE","2019-07-29T14:49:51Z","button_pressed_click","2019-07-29T14:49:55.020Z","button","50.8633166","4.42016"
"click","3619CE","2019-07-29T14:50:57Z","button_pressed_click","2019-07-29T14:50:58.961Z","button","50.8616833","4.42126"
"click","3619CE","2019-07-29T14:56:36Z","button_pressed_click","2019-07-29T14:56:39.688Z","button","50.8691666","4.41963"
"click","3619CE","2019-07-30T15:23:50Z","button_pressed_click","2019-07-30T15:23:53.441Z","button","50.8147166","4.37751"
"click","3619CE","2019-07-30T16:14:59Z","button_pressed_click","2019-07-30T16:15:01.492Z","button","50.8156","4.36066"
"click","3619CE","2019-07-31T14:01:42Z","button_pressed_click","2019-07-31T14:01:46.684Z","button","50.8407833","4.3907"
"click","3619CE","2019-07-31T14:42:56Z","button_pressed_click","2019-07-31T14:42:59.823Z","button","50.8747","4.36138"
"click","3619CE","2019-08-01T16:10:56Z","button_pressed_click","2019-08-01T16:10:59.875Z","button","50.8195","4.37036"
"click","3619CE","2019-08-01T16:12:15Z","button_pressed_click","2019-08-01T16:12:17.708Z","button","50.81745","4.37116"
"click","3619CE","2019-08-02T14:04:05Z","button_pressed_click","2019-08-02T14:04:08.547Z","button","50.8219333","4.37378"
"click","3619CE","2019-08-02T14:23:42Z","button_pressed_click","2019-08-02T14:23:45.772Z","button","50.8396833","4.35096"
"click","3619CE","2019-08-02T14:32:32Z","button_pressed_click","2019-08-02T14:32:34.483Z","button","50.8474","4.35745"
"click","3619CE","2019-08-02T15:50:25Z","button_pressed_click","2019-08-02T15:50:29.525Z","button","50.8251","4.38506"
"click","3619CE","2019-08-05T14:52:51Z","button_pressed_click","2019-08-05T14:52:55.443Z","button","50.8444","4.35636"
"click","3619CE","2019-08-05T15:01:02Z","button_pressed_click","2019-08-05T15:01:05.483Z","button","50.8402833","4.355"
"click","3619CE","2019-08-05T15:29:57Z","button_pressed_click","2019-08-05T15:30:01.631Z","button","50.8357166","4.35233"
"click","3619CE","2019-08-05T15:32:53Z","button_pressed_click","2019-08-05T15:32:57.128Z","button","50.8349666","4.3509"
"click","3619CE","2019-08-05T15:34:05Z","button_pressed_click","2019-08-05T15:34:07.667Z","button","50.8377166","4.34855"
"click","3619CE","2019-08-05T15:43:47Z","button_pressed_click","2019-08-05T15:43:52.226Z","button","50.83565","4.34851"
"click","3619CE","2019-08-05T16:31:11Z","button_pressed_click","2019-08-05T16:31:14.449Z","button","50.83785","4.35106"
"click","3619CE","2019-08-06T16:12:54Z","button_pressed_click","2019-08-06T16:12:57.572Z","button","50.8486666","4.30548"
"click","3619CE","2019-08-07T14:55:39Z","button_pressed_click","2019-08-07T14:55:43.191Z","button","50.8628666","4.42886"
"click","3619CE","2019-08-07T15:31:12Z","button_pressed_click","2019-08-07T15:31:16.481Z","button","50.8391333","4.39866"
"click","3619CE","2019-08-08T13:56:43Z","button_pressed_click","2019-08-08T13:56:48.606Z","button","50.8139833","4.37763"
"click","3619CE","2019-08-08T15:01:53Z","button_pressed_click","2019-08-08T15:01:56.413Z","button","50.8631333","4.43143"
"click","3619CE","2019-08-08T15:03:00Z","button_pressed_click","2019-08-08T15:03:02.944Z","button","50.8631","4.43151"
"click","3619CE","2019-08-08T15:38:16Z","button_pressed_click","2019-08-08T15:38:20.703Z","button","50.8562833","4.40791"
"click","3619CE","2019-08-09T14:09:04Z","button_pressed_click","2019-08-09T14:09:08.393Z","button","50.7976666","4.4142"
"click","3619CE","2019-08-09T15:12:24Z","button_pressed_click","2019-08-09T15:12:27.948Z","button","50.8489833","4.46111"
"click","3619CE","2019-08-09T15:14:44Z","button_pressed_click","2019-08-09T15:14:46.536Z","button","50.8514166","4.45213"
"click","3619CE","2019-08-09T15:33:29Z","button_pressed_click","2019-08-09T15:33:33.827Z","button","50.8382166","4.40513"
"click","3619CE","2019-08-09T15:36:15Z","button_pressed_click","2019-08-09T15:36:18.541Z","button","50.8397","4.398"
"click","3619CE","2019-08-09T16:10:12Z","button_pressed_click","2019-08-09T16:10:15.838Z","button","50.83945","4.37075"
"click","3619CE","2019-08-09T16:11:23Z","button_pressed_click","2019-08-09T16:11:25.559Z","button","50.83985","4.37063"
"click","3619CE","2019-08-09T16:23:47Z","button_pressed_click","2019-08-09T16:23:51.065Z","button","50.8238333","4.38611"
"click","3619CE","2019-08-09T16:25:43Z","button_pressed_click","2019-08-09T16:25:45.940Z","button","50.8207833","4.38648"
"click","3619CE","2019-08-12T14:43:59Z","button_pressed_click","2019-08-12T14:44:02.775Z","button","50.8139333","4.38521"
"click","3619CE","2019-08-12T14:49:39Z","button_pressed_click","2019-08-12T14:49:41.476Z","button","50.8200333","4.39495"
"click","3619CE","2019-08-12T15:59:24Z","button_pressed_click","2019-08-12T15:59:27.514Z","button","50.8424666","4.39366"
"click","3619CE","2019-08-13T13:54:35Z","button_pressed_click","2019-08-13T13:54:39.654Z","button","50.81315","4.37871"
"click","3619CE","2019-08-13T13:55:49Z","button_pressed_click","2019-08-13T13:55:52.337Z","button","50.8135166","4.37745"
"click","3619CE","2019-08-13T13:56:58Z","button_pressed_click","2019-08-13T13:57:00.768Z","button","50.8132833","4.37831"
"click","3619CE","2019-08-13T14:47:03Z","button_pressed_click","2019-08-13T14:47:07.914Z","button","50.8396666","4.37041"
"click","3619CE","2019-08-13T16:07:23Z","button_pressed_click","2019-08-13T16:07:26.516Z","button","50.8133666","4.37771"
"click","3619CE","2019-08-14T13:57:55Z","button_pressed_click","2019-08-14T13:57:59.456Z","button","50.81295","4.37846"
"click","3619CE","2019-08-14T13:59:10Z","button_pressed_click","2019-08-14T13:59:13.078Z","button","50.8152166","4.37698"
"click","3619CE","2019-08-14T16:24:52Z","button_pressed_click","2019-08-14T16:24:57.325Z","button","50.8125","4.4263"
"click","3619CE","2019-08-15T13:54:33Z","button_pressed_click","2019-08-15T13:54:36.673Z","button","50.8151","4.37663"
"click","3619CE","2019-08-15T13:55:41Z","button_pressed_click","2019-08-15T13:55:43.436Z","button","50.8152666","4.37645"
"click","3619CE","2019-08-15T14:40:15Z","button_pressed_click","2019-08-15T14:40:18.841Z","button","50.85875","4.35908"
"click","3619CE","2019-08-15T15:15:06Z","button_pressed_click","2019-08-15T15:15:08.444Z","button","50.8541833","4.36881"
"click","3619CE","2019-08-15T15:40:36Z","button_pressed_click","2019-08-15T15:40:40.796Z","button","50.8572833","4.39678"
"click","3619CE","2019-08-20T14:50:24Z","button_pressed_click","2019-08-20T14:50:29.045Z","button","50.8491666","4.31125"
"click","3619CE","2019-08-20T15:26:39Z","button_pressed_click","2019-08-20T15:26:42.800Z","button","50.8468333","4.31651"
"double_click","3619CE","2019-08-21T12:18:21Z","button_pressed_double_click","2019-08-21T12:18:23.695Z","button","50.8431833","4.39201"
"click","3619CE","2019-08-21T12:24:18Z","button_pressed_click","2019-08-21T12:24:22.264Z","button","50.8417833","4.38611"
"click","3619CE","2019-08-21T12:41:39Z","button_pressed_click","2019-08-21T12:41:48.542Z","button","50.827","4.36281"
"click","3619CE","2019-08-21T12:46:14Z","button_pressed_click","2019-08-21T12:46:16.867Z","button","50.82845","4.3607"
"click","3619CE","2019-08-21T12:50:28Z","button_pressed_click","2019-08-21T12:50:30.731Z","button","50.8362","4.35603"
"double_click","3619CE","2019-08-21T13:05:30Z","button_pressed_double_click","2019-08-21T13:05:34.061Z","button","50.83195","4.37333"
"click","3619CE","2019-08-21T13:13:43Z","button_pressed_click","2019-08-21T13:13:56.275Z","button","50.84555","4.36665"
"click","3619CE","2019-08-21T13:26:25Z","button_pressed_click","2019-08-21T13:26:35.082Z","button","50.86165","4.36761"
"click","3619CE","2019-08-21T14:06:52Z","button_pressed_click","2019-08-21T14:06:58.156Z","button","50.8544333","4.36615"
"double_click","3619CE","2019-08-21T14:30:16Z","button_pressed_double_click","2019-08-21T14:30:19.433Z","button","50.8393666","4.38273"
"double_click","3619CE","2019-08-21T14:41:18Z","button_pressed_double_click","2019-08-21T14:41:21.434Z","button","50.8466333","4.39353"
"double_click","3619CE","2019-08-21T16:28:23Z","button_pressed_double_click","2019-08-21T16:28:26.364Z","button","50.85255","4.38768"
"click","3619CE","2019-08-21T17:24:06Z","button_pressed_click","2019-08-21T17:24:08.428Z","button","50.8427166","4.39713"
"click","3619CE","2019-08-23T14:12:06Z","button_pressed_click","2019-08-23T14:12:09.425Z","button","50.84755","4.3507"
"click","3619CE","2019-08-23T14:19:52Z","button_pressed_click","2019-08-23T14:19:56.443Z","button","50.83625","4.34536"
"click","3619CE","2019-08-23T14:25:02Z","button_pressed_click","2019-08-23T14:25:05.403Z","button","50.83155","4.35098"
"click","3619CE","2019-08-23T14:40:28Z","button_pressed_click","2019-08-23T14:40:31.379Z","button","50.8369166","4.35893"
"click","3619CE","2019-08-23T14:41:47Z","button_pressed_click","2019-08-23T14:41:48.916Z","button","50.8364666","4.35896"
"click","3619CE","2019-08-23T14:58:32Z","button_pressed_click","2019-08-23T14:58:35.249Z","button","50.8397666","4.37341"
"click","3619CE","2019-08-23T15:05:51Z","button_pressed_click","2019-08-23T15:05:53.352Z","button","50.8365333","4.36643"
"click","3619CE","2019-08-23T15:14:47Z","button_pressed_click","2019-08-23T15:14:50.731Z","button","50.8273833","4.36351"
"click","3619CE","2019-08-23T15:22:39Z","button_pressed_click","2019-08-23T15:22:42.205Z","button","50.8276666","4.37251"
"click","3619CE","2019-08-23T15:35:37Z","button_pressed_click","2019-08-23T15:35:39.654Z","button","50.8274666","4.38093"
"click","3619CE","2019-08-23T15:39:19Z","button_pressed_click","2019-08-23T15:39:21.864Z","button","50.8321","4.37546"
"double_click","3619CE","2019-08-23T16:52:35Z","button_pressed_double_click","2019-08-23T16:52:40.040Z","button","50.8268","4.37243"
"click","3619CE","2019-08-28T13:55:57Z","button_pressed_click","2019-08-28T13:56:01.045Z","button","50.8239166","4.37586"
"click","3619CE","2019-08-28T14:03:16Z","button_pressed_click","2019-08-28T14:03:19.186Z","button","50.8161666","4.39103"
"click","3619CE","2019-08-28T14:14:20Z","button_pressed_click","2019-08-28T14:14:24.423Z","button","50.8174833","4.37643"
"click","3619CE","2019-08-28T14:16:59Z","button_pressed_click","2019-08-28T14:17:02.409Z","button","50.82045","4.37318"
"click","3619CE","2019-08-28T14:49:09Z","button_pressed_click","2019-08-28T14:49:12.652Z","button","50.81315","4.34711"
"click","3619CE","2019-08-28T14:55:39Z","button_pressed_click","2019-08-28T14:55:43.802Z","button","50.8183","4.36401"
"click","3619CE","2019-08-28T15:04:29Z","button_pressed_click","2019-08-28T15:04:33.356Z","button","50.8265333","4.3588"
"click","3619CE","2019-08-28T15:35:49Z","button_pressed_click","2019-08-28T15:35:53.241Z","button","50.8327166","4.3754"
"double_click","3619CE","2019-08-28T15:37:05Z","button_pressed_double_click","2019-08-28T15:37:08.192Z","button","50.8349833","4.38031"
"click","3619CE","2019-08-28T15:41:02Z","button_pressed_click","2019-08-28T15:41:04.928Z","button","50.8314166","4.38678"
"click","3619CE","2019-08-28T15:42:22Z","button_pressed_click","2019-08-28T15:42:24.930Z","button","50.8309333","4.38716"
"click","3619CE","2019-08-28T15:44:07Z","button_pressed_click","2019-08-28T15:44:09.828Z","button","50.8300333","4.39041"
"double_click","3619CE","2019-08-28T15:54:06Z","button_pressed_double_click","2019-08-28T15:54:08.713Z","button","50.83845","4.39746"
"click","3619CE","2019-08-28T16:48:29Z","button_pressed_click","2019-08-28T16:48:32.709Z","button","50.8422666","4.37828"
"click","3619CE","2019-08-28T16:52:01Z","button_pressed_click","2019-08-28T16:52:03.365Z","button","50.84235","4.37818"
"click","3619CE","2019-08-28T17:12:04Z","button_pressed_click","2019-08-28T17:12:08.449Z","button","50.8308333","4.39005"
"click","3619CE","2019-08-29T14:02:04Z","button_pressed_click","2019-08-29T14:02:07.333Z","button","50.8255833","4.37251"
"click","3619CE","2019-08-29T14:15:02Z","button_pressed_click","2019-08-29T14:15:06.085Z","button","50.8332","4.34568"
"click","3619CE","2019-08-29T14:32:36Z","button_pressed_click","2019-08-29T14:32:40.081Z","button","50.8555666","4.35713"
"click","3619CE","2019-08-29T14:46:56Z","button_pressed_click","2019-08-29T14:46:59.922Z","button","50.8502833","4.34645"
"click","3619CE","2019-08-29T14:49:00Z","button_pressed_click","2019-08-29T14:49:02.868Z","button","50.8488","4.3469"
"click","3619CE","2019-08-29T15:10:41Z","button_pressed_click","2019-08-29T15:10:44.782Z","button","50.82795","4.32785"
"double_click","3619CE","2019-08-29T15:31:25Z","button_pressed_double_click","2019-08-29T15:31:29.056Z","button","50.8242","4.3342"
"click","3619CE","2019-08-29T15:43:27Z","button_pressed_click","2019-08-29T15:43:31.538Z","button","50.8051166","4.33076"
"click","3619CE","2019-08-29T15:44:36Z","button_pressed_click","2019-08-29T15:44:38.505Z","button","50.8072833","4.3349"
"click","3619CE","2019-08-29T15:55:28Z","button_pressed_click","2019-08-29T15:55:31.436Z","button","50.8122","4.35401"
"click","3619CE","2019-08-29T16:17:54Z","button_pressed_click","2019-08-29T16:17:57.205Z","button","50.8207833","4.3812"
"click","3619CE","2019-08-29T16:34:33Z","button_pressed_click","2019-08-29T16:34:36.582Z","button","50.8313333","4.3992"
"click","3619CE","2019-08-29T16:42:18Z","button_pressed_click","2019-08-29T16:42:22.103Z","button","50.83655","4.40135"
"click","3619CE","2019-08-29T17:17:12Z","button_pressed_click","2019-08-29T17:17:17.130Z","button","50.8455","4.3937"
"click","3619CE","2019-08-29T17:18:24Z","button_pressed_click","2019-08-29T17:18:26.549Z","button","50.8430333","4.39238"
"click","3619CE","2019-08-30T13:45:23Z","button_pressed_click","2019-08-30T13:45:33.940Z","button","50.8313833","4.38641"
"click","3619CE","2019-08-30T14:24:04Z","button_pressed_click","2019-08-30T14:24:05.947Z","button","50.8156333","4.32695"
"click","3619CE","2019-08-30T14:48:52Z","button_pressed_click","2019-08-30T14:48:56.548Z","button","50.8324","4.34298"
"click","3619CE","2019-08-30T15:03:08Z","button_pressed_click","2019-08-30T15:03:12.658Z","button","50.84245","4.36031"
"click","3619CE","2019-08-30T15:31:05Z","button_pressed_click","2019-08-30T15:31:08.710Z","button","50.8461","4.35906"
"double_click","3619CE","2019-08-30T16:09:40Z","button_pressed_double_click","2019-08-30T16:09:43.939Z","button","50.8229333","4.3439"
"click","3619CE","2019-08-30T16:16:00Z","button_pressed_click","2019-08-30T16:16:05.005Z","button","50.8231666","4.35633"
"click","3619CE","2019-08-30T16:17:09Z","button_pressed_click","2019-08-30T16:17:12.452Z","button","50.82355","4.35776"
"click","3619CE","2019-08-30T16:21:43Z","button_pressed_click","2019-08-30T16:21:45.710Z","button","50.8288166","4.3615"
"click","3619CE","2019-08-30T16:34:22Z","button_pressed_click","2019-08-30T16:34:25.680Z","button","50.8222833","4.3823"
"click","3619CE","2019-08-30T16:43:18Z","button_pressed_click","2019-08-30T16:43:21.997Z","button","50.8326166","4.39466"
"click","3619CE","2019-08-31T13:32:57Z","button_pressed_click","2019-08-31T13:33:01.780Z","button","50.84205","4.38646"
"click","3619CE","2019-08-31T14:20:45Z","button_pressed_click","2019-08-31T14:20:49.079Z","button","50.84665","4.37471"
"click","3619CE","2019-08-31T16:01:42Z","button_pressed_click","2019-08-31T16:01:46.653Z","button","50.8268333","4.39016"
"click","3619CE","2019-09-01T14:38:54Z","button_pressed_click","2019-09-01T14:38:57.363Z","button","50.8517833","4.40015"
"click","3619CE","2019-09-01T14:53:27Z","button_pressed_click","2019-09-01T14:53:33.902Z","button","50.8566666","4.41371"
"click","3619CE","2019-09-01T14:57:56Z","button_pressed_click","2019-09-01T14:57:58.593Z","button","50.8601166","4.41896"
"click","3619CE","2019-09-01T14:59:03Z","button_pressed_click","2019-09-01T14:59:05.828Z","button","50.86255","4.41961"
"click","3619CE","2019-09-01T15:12:04Z","button_pressed_click","2019-09-01T15:12:08.519Z","button","50.8696166","4.4001"
"click","3619CE","2019-09-13T17:15:18Z","button_pressed_click","2019-09-13T17:15:21.994Z","button","50.8390833","4.38818"
"click","3619CE","2019-09-14T10:58:35Z","button_pressed_click","2019-09-14T10:58:36.807Z","button","50.8740166","4.50658"
"double_click","3619CE","2019-09-14T15:03:35Z","button_pressed_double_click","2019-09-14T15:03:39.062Z","button","50.879","4.37248"`;